import React from 'react';
import SEO from "../components/common/layout/seo"
import PageLayout from "../components/common/layout/pageLayout";

const Contact = () => {
  return (
    <PageLayout>
      <SEO title="Contact" />
      <h1>Get In Touch</h1>
      <h2>We'd love to hear from you! Write to us at <a href="mailto:contact@hedera.in">contact@hedera.in</a> and we'll be happy to help answer your questions</h2>
      <div>
        <h4>Address</h4>
        <address>
          Hedera Technology (OPC) Pvt Ltd<br></br>
        204A, Silpa Lepakshi Nagar<br></br>
        Anantapur, Andhra Pradesh - 515003<br></br>
        India<br></br>
        </address>
      </div>
    </PageLayout>
  );
}

export default Contact;

